<template>
  <v-card>
    <DataTablePagination
      :page="page"
      :total-items="totalItems"
      :items="candidates"
      :total-pages.sync="totalPages"
      :headers="headers"
      title="Calon"
      subtitle="Halaman untuk menambahkan calon kandidat"
      add-btn="Tambah Data"
      @add-item="showFormAdd"
    >
    </DataTablePagination>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      @close="modalDialog = false"
    >
      <template v-slot:header>
        Tambah Calon
      </template>
      <template v-slot:body>
        <div>
          <v-text-field
            label="Nama Calon"
            dense
            outlined
          ></v-text-field>
        </div>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          large
        >
          Tambah
        </v-btn>
        <v-btn
          color="primary"
          outlined
          large
        >
          Batal
        </v-btn>
      </template>
    </ModalDialog>
  </v-card>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'EvotingCandidate',
  components: {
    DataTablePagination,
    ModalDialog,
  },
  data() {
    return {
      page: 1,
      totalItems: 10,
      totalPages: 0,
      width: '600',
      modalDialog: false,
      headers: [
        { text: 'No', value: 'no' },
        { text: 'Nama Calon', value: 'candidate_name' },
        { text: 'Actions', value: 'actions' },
      ],
      candidates: [
        { no: '#', candidate_name: 'Chairul Tanjung' },
        { no: '#', candidate_name: 'Rina Tanjung' },
        { no: '#', candidate_name: 'Putera Tanjung' },
        { no: '#', candidate_name: 'Dina Lestari' },
      ],
    }
  },
  methods: {
    showFormAdd() {
      this.modalDialog = true
    },
  },
}
</script>

<style>
</style>
